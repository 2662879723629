body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(14, 14, 14);
  color: white;
  scroll-behavior: smooth;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: translateY(10px);
  }
  50%{
    opacity:0;
  }
  75%{
    opacity:0;
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
      opacity: 0;
      transform: translateX(-10px);
  }
  50%{
    opacity:0;
  }
  75%{
    opacity:0;
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}
